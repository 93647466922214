@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.wmde-markdown ol {
  list-style-type: decimal;
}

.wmde-markdown ul {
  list-style: disc;
}

@layer components {
  .onb-emphasis {
    @apply font-bold text-primary-500;
  }
}